import { template as template_c9e4c78f813d4b62b3b4b536412e063c } from "@ember/template-compiler";
const FKLabel = template_c9e4c78f813d4b62b3b4b536412e063c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
