import { template as template_240bb0b3aaa44f54972df9fcb552918f } from "@ember/template-compiler";
const FKText = template_240bb0b3aaa44f54972df9fcb552918f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
