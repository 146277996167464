import { template as template_593c52fc252c48e5b1588062cb2ae627 } from "@ember/template-compiler";
const WelcomeHeader = template_593c52fc252c48e5b1588062cb2ae627(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
