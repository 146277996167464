import { template as template_e9f69a11ba7749e99504aadfd3387d3a } from "@ember/template-compiler";
const SidebarSectionMessage = template_e9f69a11ba7749e99504aadfd3387d3a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
