import { template as template_525f2a6a37e7450fbac9a51a3e3438e7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_525f2a6a37e7450fbac9a51a3e3438e7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
